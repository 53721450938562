<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>


            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">



                    <article data-history-node-id="1078"
                             role="article"
                             about="/content/jewellery"
                             class="node node--type-centurion-page node--view-mode-full">





                        <div class="node__content">

                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">


                                    <div
                                         class="paragraph paragraph--type--block-reference paragraph--view-mode--default">


                                        <div
                                             class="field field--name-field-block-ref field--type-entity-reference field--label-hidden field__item">
                                            <div id="block-centurionexperiencesoffers"
                                                 class="block block-block-content block-block-content8194d79d-7a70-4a78-998a-545282d42555">



                                                <div
                                                     class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                                                    <div class="gray-white">
                                                        <div class="container">
                                                            <div class="row g-md-0 black-bg">
                                                                <div class="col-md-5">
                                                                    <div class="title">{{ $t('Centurion') }}<br />
                                                                        {{ $t('experiences & offers') }}</div>
                                                                </div>

                                                                <div class="col-md-7">
                                                                    <div class="image"><img
                                                                             alt="Centurion experiences & offers logo"
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/cent-exp-and-offers.jpg`)" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-experiences-offers paragraph--view-mode--default color-black">
                                        <div class="cent-gray-bg">
                                            <div class="container">
                                                <div class="row g-md-0">
                                                    <div
                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative">
                                                        <div class="cent-exp-offers mw-100">

                                                            <div
                                                                 class="field field--name-field-cent-experiences-offer-img field--type-image field--label-hidden field__item">
                                                                <img :src="require(`@/assets/images/centurion/jewellery-banner.jpg`)"
                                                                     width="1300"
                                                                     height="543"
                                                                     alt=""
                                                                     loading="lazy"
                                                                     typeof="foaf:Image"
                                                                     class="img-fluid" />

                                                            </div>

                                                            <SubMenu></SubMenu>

                                                            <div
                                                                 class="field field--name-field-cent-offers field--type-entity-reference-revisions field--label-hidden field__items">
                                                                <div class="field__item">
                                                                    <div
                                                                         class="paragraph paragraph--type--cent-offers-container paragraph--view-mode--default">
                                                                        <div class="row margin justify-content-center">

                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/timevallee_0.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`offerone`) }} </p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`offeronedes`) }}
                                                                                        </p>

                                                                                        <p>{{ $t(`offeronedate`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/chaumet-en-100.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Chaumet`) }} </p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p class="currency-all">{{ $t(`Enjoy 8% cashback on
                                                                                            every transaction when using
                                                                                            your Centurion Card at
                                                                                            Chaumet Saudi Arabia, with a
                                                                                            minimum spend of 50,000 SAR
                                                                                            required. Terms
                                                                                            & Conditions apply.`) }}
                                                                                        </p>

                                                                                        <p>{{ $t(`Offer Valid till 30
                                                                                            April 2025.`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/pasqualebruni.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Pasquale Bruni`) }}
                                                                                        </p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Enjoy a flat discount
                                                                                            of 12% fon all collections,
                                                                                            including high jewelry
                                                                                            on every transaction when
                                                                                            using your Centurion Card at
                                                                                            Pasquale Bruni
                                                                                            Saudi Arabia Terms &
                                                                                            Conditions apply.`) }}</p>

                                                                                        <p>{{ $t(`Offer Valid till 30
                                                                                            April 2025.`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/cle_0.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`offertwo`)
                                                                                            }}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`offertwodes`) }}</p>

                                                                                        <p>{{ $t(`offertwodate`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/aristocrazy-1.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Aristocrazy Jeddah`)
                                                                                            }}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Meet Aristocrazy, the
                                                                                            leading experiential jewelry
                                                                                            brand that just opened
                                                                                            its doors in Jeddah!
                                                                                            Discover unique pieces and
                                                                                            enjoy stacking refined
                                                                                            jewelry. Enjoy this
                                                                                            bedazzling experience with
                                                                                            an exclusive 2X Membership
                                                                                            Rewards points on all
                                                                                            purchases at Aristocrazy
                                                                                            boutiques using Your
                                                                                            Centurion Card. Terms &
                                                                                            Conditions apply.`) }}</p>

                                                                                        <p>{{ $t(`Offer Valid till 31
                                                                                            July 2025.`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/suarez.png`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`SUAREZ Jeddah`) }}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`At Suarez Jewelry,
                                                                                            every dollar you spend
                                                                                            grants you unparalleled
                                                                                            privileges. Make your
                                                                                            shopping experience
                                                                                            enjoyable with 10x
                                                                                            Membership
                                                                                            Rewards® points for every
                                                                                            USD spent at Suarez Boutique
                                                                                            at Alkhayyat Center
                                                                                            using Your Centurion Card.
                                                                                            Terms & Conditions apply.`)
                                                                                            }}</p>

                                                                                        <p>{{ $t(`Offer Valid till 30
                                                                                            June 2025`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </article>

                </div>

            </div>


        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';
import SubMenu from './sub-menu.vue'

export default {
    components: {
        CenturionHeader,
        CenturionFooter,
        SubMenu
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.gray-white {
    background: linear-gradient(to bottom, #fff 80%, #f8f8f8 20%);
}

.black-bg {
    background-color: #000000;
}

.title {
    font-size: 1.5em !important;
    font-weight: bold;
    color: #ffffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    transform: translateY(1.5em);
    text-align: center;
}

.image img {}

@media only screen and (max-width: 576px) {
    .black-bg {
        margin-bottom: 20%;
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-top: 1em;
    }

    .image {
        transform: translateY(50%);
        text-align: center;
        margin-top: -25%;
    }
}
</style>
